import React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const AccountDeletionPolicy = () => {
  return (
    <div className="flex w-full min-h-screen items-center justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc] p-4">
      <div className="w-full max-w-2xl bg-white p-8 shadow-2xl rounded-3xl">
        {/* Заголовок страницы */}
        <h1 className="text-3xl font-bold text-center mb-6">Политика удаления аккаунта</h1>

        {/* Информация о приложении и разработчике */}
        <p className="text-lg text-center mb-4">
          Приложение <strong>"MIRA"</strong> разработано компанией <strong>Meridian</strong>
        </p>

        {/* Пошаговая инструкция по удалению аккаунта */}
        <h2 className="text-2xl font-semibold mt-4">Как удалить аккаунт</h2>
        <ol className="list-decimal list-inside ml-4 mt-2 space-y-2">
          <li>
            <strong>Перейдите в боковое меню.</strong> Найдите раздел «Личный кабинет».
          </li>
          <li>
            <strong>Нажмите кнопку "Удалить аккаунт".</strong> Подтвердите удаление, чтобы начать процедуру.
          </li>
        </ol>

        {/* Информация о данных */}
        <h2 className="text-2xl font-semibold mt-6">Какие данные будут удалены</h2>
        <p className="mt-2">
          При удалении аккаунта будут удалены <strong>все данные</strong>, включая персональную информацию, историю действий и настройки профиля.
        </p>
        <p className="mt-2">Данные удаляются безвозвратно сразу после подтверждения запроса.</p>

        {/* Секция технической поддержки через Telegram */}
        <div className="mt-6">
          <div className="grid grid-cols-12 gap-4 items-center justify-items-center border-b p-2">
            <div className="col-span-1">
              <TelegramIcon className="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-[#359BFF]" />
            </div>
            <div className="col-span-10 justify-self-start">
              <p className="select-none text-wrap">Телеграм канал</p>
              <p className="text-xs text-wrap select-none">Если у вас возникли вопросы, пожалуйста, свяжитесь с нашей службой поддержки.</p>
            </div>
            <div className="col-span-1">
              <a target="_blank" href="https://t.me/zabota_mr_bot" rel="noopener noreferrer">
                <KeyboardDoubleArrowRightIcon className="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-gray-900 hover:fill-orange-500 animate-pulse" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDeletionPolicy;
