import * as React from 'react';
import Login from './Login';
import Registration from './Registration';
import TelegramIcon from '@mui/icons-material/Telegram';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ForumIcon from '@mui/icons-material/Forum';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import BtnFilled from '../components/BtnOutlined';
import HtmlEditor from '../components/HtmlEditor';
import HtmlViewer from '../components/HtmlViewer';
import { getInstructionsApi2, addInstructionApi2, editInstructionApi2, getPermitApi2 } from '../api/api2';
import { ToastContainer, toast } from 'react-toastify';
import Modal from '../components/Modal';
import Input from '../components/Input';
import FileUploader from '../components/FileUploaderBody';

const Authorization = ({}) => {
  const tabActiveStyle = (index, length) => {
    let style =
      'inline-block p-1 w-full text-base text-gray-800 border-t border-x border-gray-300 bg-white font-bold active cursor-pointer select-none truncate';
    if (index === 0) {
      style += ' rounded-tl-3xl';
    } else if (index === length - 1) {
      style += ' rounded-tr-3xl';
    }
    return style;
  };

  const tabInactiveStyle =
    'inline-block p-1 w-full text-base bg-opacity-0 border-b border-gray-300 text-gray-600 hover:text-gray-800 font-bold cursor-pointer select-none truncate';
  const [tabs, setTabs] = React.useState([{ name: 'Конакты', active: true }, { name: 'Инструкции' }]);

  const [editPermit, setEditPermit] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [instructions, setInstructions] = React.useState([]);
  const [instToAdd, setInstToAdd] = React.useState({ type: 'htmljs' });
  const [instToShow, setInstToShow] = React.useState({});

  const makeActive = async (index) => {
    setTabs([...tabs.map((tab, i) => ({ ...tab, active: index === i ? true : false }))]);
    if (index === 1) {
      await getInstructions();
    }
  };

  React.useEffect(() => {
    const get = async () => {
      await getPermission();
    };
    get();
  }, []);

  async function getPermission() {
    await getPermitApi2(
      function (data) {
        if (data?.edit === 'ok') {
          setEditPermit(true);
          setTabs([...tabs, { name: 'Загрузчик' }]);
        }
      },
      function (err) {
        setEditPermit(false);
      }
    );
  }

  async function getInstructions() {
    await getInstructionsApi2(
      function (data) {
        setInstructions(data);
        //setRefreshing(false);
      },
      function (err) {
        //setRefreshing(false);
        toast.error(err.error ? err.error : 'Ошибка сервера', { hideProgressBar: false });
      }
    );
  }

  async function addInstruction() {
    await addInstructionApi2(
      instToAdd,
      async function (data) {
        setShowAddModal(false);
        await getInstructions();
        //setRefreshing(false);
      },
      function (err) {
        //setRefreshing(false);
        toast.error(err.error ? err.error : 'Ошибка сервера', { hideProgressBar: false });
      }
    );
  }

  async function editInstruction() {
    await editInstructionApi2(
      instToShow,
      async function (data) {
        setShowModal(false);
        await getInstructions();
        //setRefreshing(false);
      },
      function (err) {
        //setRefreshing(false);
        toast.error(err.error ? err.error : 'Ошибка сервера', { hideProgressBar: false });
      }
    );
  }

  const add = async () => {
    setShowAddModal(true);
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <div class="flex w-full min-h-screen p-4 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
        <div class="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-4xl">
          <div class="rounded-lg m-2">
            <div class="text-sm font-medium text-center text-gray-500  dark:text-gray-400 dark:border-gray-700">
              <ul class="flex flex-nowrap">
                {tabs.map((tab, index) => {
                  return (
                    <p
                      key={index}
                      onClick={() => {
                        makeActive(index);
                      }}
                      class={tab.active ? tabActiveStyle(index, tabs.length) : tabInactiveStyle}>
                      {tab.name}
                    </p>
                  );
                })}
              </ul>
            </div>
            {tabs[0]?.active && (
              <>
                <div class="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
                  <form class="bg-white shadow-2xl rounded-b-3xl border-b border-x border-gray-300 px-6 pt-6 pb-8">
                    <div class="grid grid-cols-12 gap-4 items-center justify-items-center border-b p-2 ">
                      <div class="col-span-1">
                        <TelegramIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-[#359BFF]"></TelegramIcon>
                      </div>
                      <div class="col-span-10 justify-self-start">
                        <p class="select-none text-wrap">Телеграм канал</p>
                        <p class="text-xs text-wrap select-none">Следите за новостями и задавайте вопросы</p>
                      </div>

                      <div class="col-span-1">
                        <a target="_blank" href={'https://t.me/zabota_mr_bot'}>
                          <KeyboardDoubleArrowRightIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-gray-900 hover:fill-orange-500 animate-pulse"></KeyboardDoubleArrowRightIcon>
                        </a>
                      </div>
                    </div>

                    <div class="grid grid-cols-12 gap-4 items-center justify-items-center border-b p-2 ">
                      <div class="col-span-1">
                        <EmailOutlinedIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-orange-500"></EmailOutlinedIcon>
                      </div>
                      <div class="col-span-10 justify-self-start">
                        <p class="text-wrap">info@meridian.pro</p>
                        <p class="text-xs text-wrap select-none">Пишите нам письма с вопросами и предложениями</p>
                      </div>

                      <div class="col-span-1">
                        {/* <a href='mailto:info@meridian.pro'>
                        <KeyboardDoubleArrowRightIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-gray-900 hover:fill-orange-500 animate-pulse"></KeyboardDoubleArrowRightIcon>
                      </a>*/}
                      </div>
                    </div>

                    <div class="grid grid-cols-12 gap-4 items-center justify-items-center border-b p-2 ">
                      <div class="col-span-1">
                        <PhoneIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-orange-500"></PhoneIcon>
                      </div>
                      <div class="col-span-10 justify-self-start">
                        <p class="text-wrap">+7 (495) 725-27-55, *9523</p>
                        <p class="text-xs text-wrap select-none">Вы так-же можете позвонить нам, *короткий номер бесплатный по всей территории РФ</p>
                      </div>

                      <div class="col-span-1">
                        {/* <a href='mailto:info@meridian.pro'>
                        <KeyboardDoubleArrowRightIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-gray-900 hover:fill-orange-500 animate-pulse"></KeyboardDoubleArrowRightIcon>
                      </a>*/}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {tabs[1]?.active && (
              <>
                <div class="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
                  <form class="bg-white shadow-2xl rounded-b-3xl border-b border-x border-gray-300 px-8 pt-6 pb-8 mb-4">
                    {instructions.length !== 0 && (
                      <>
                        {instructions.map((inst, index) => {
                          return (
                            <>
                              <div
                                class="grid grid-cols-12 gap-4 items-center justify-items-center border-b p-2 hover:bg-gray-100 hover:cursor-pointer"
                                onClick={() => (setInstToShow(inst), setShowModal(true))}>
                                {/* <div class="col-span-1">
                                  <SettingsIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-orange-500"></SettingsIcon>
                                </div>*/}
                                <div class="col-span-11 justify-self-start">
                                  <p class="select-none text-wrap">{inst?.title}</p>
                                  <p class="text-xs text-wrap select-none">{inst?.descr}</p>
                                </div>

                                {/*<div class="col-span-1">
                                  <ModeEditOutlineOutlinedIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-gray-900 hover:fill-orange-500"></ModeEditOutlineOutlinedIcon>
                                </div>*/}

                                <div class="col-span-1">
                                  <KeyboardDoubleArrowRightIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-gray-900 hover:fill-orange-500 animate-pulse"></KeyboardDoubleArrowRightIcon>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                    {editPermit && (
                      <div class="w-full mb-0 mt-8">
                        <BtnFilled title="Добавить" btnCb={() => add()}></BtnFilled>
                      </div>
                    )}
                  </form>
                </div>
                <Modal
                  showModal={showAddModal}
                  setShowModal={setShowAddModal}
                  saveCb={() => addInstruction()}
                  closeCb={() => getInstructions()}
                  title="Добавление инструкции"
                  error={null}>
                  <div class="w-full">
                    <Input
                      value={instToAdd.title}
                      inputCb={(e) => setInstToAdd({ ...instToAdd, title: e.target.value })}
                      label="Заголовок"
                      id="title"
                      type="text"
                      placeholder={'Подключение к WiFi'}></Input>
                  </div>
                  <div class="w-full mt-4">
                    <Input
                      value={instToAdd.descr}
                      inputCb={(e) => setInstToAdd({ ...instToAdd, descr: e.target.value })}
                      label="Описание"
                      id="descr"
                      type="text"
                      placeholder={'Здесь вы найдете инструкцию о том как подключить контроллер к WiFi роутеру'}></Input>
                  </div>
                </Modal>

                <Modal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  saveCb={() => editInstruction()}
                  closeCb={() => getInstructions()}
                  title={instToShow?.title}
                  showSave={editPermit}
                  error={null}
                  classWidth="relative w-11/12 lg:w-1/2 my-6 mx-2">
                  {editPermit ? (
                    <>
                      {preview ? <HtmlViewer html={instToShow}></HtmlViewer> : <HtmlEditor html={instToShow} setHtml={setInstToShow}></HtmlEditor>}
                      <div class="w-full mb-0 mt-8">
                        <BtnFilled title={preview ? 'Редактор' : 'Предпросмотр'} btnCb={() => setPreview((p) => !p)}></BtnFilled>
                      </div>
                    </>
                  ) : (
                    <HtmlViewer html={instToShow}></HtmlViewer>
                  )}
                </Modal>
              </>
            )}

            {tabs[2]?.active && editPermit && (
              <>
                <div class="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
                  <form class="bg-white shadow-2xl rounded-b-3xl border-b border-x border-gray-300 px-8 pt-6 pb-8 mb-4">
                    <FileUploader></FileUploader>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Authorization;
