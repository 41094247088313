import * as React from 'react';
import { useState, useEffect } from 'react';
import { logout, getUserApi, deleteAccount } from '../api/api'; // Предполагается, что такая функция существует
import { useNavigate } from 'react-router-dom';
import AlertAccent from '../components/AlertAccent';
import BtnFilled from '../components/BtnFilled';
import Modal from '../components/Modal';

const User = ({ user, setUser, setAuthorized }) => {
  const navigate = useNavigate();

  // Стейт для управления отображением модального окна удаления аккаунта
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // Стейт для хранения возможной ошибки при удалении
  const [deleteError, setDeleteError] = useState('');
  // Стейт для хранения информации об успешном удалении
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // Состояние для обратного отсчёта (в секундах)
  const [countdown, setCountdown] = useState(30);
  // Флаг, активен ли обратный отсчёт
  const [countdownActive, setCountdownActive] = useState(false);

  const out = async () => {
    await logout();
    await getUserApi(
      (data) => {
        setUser(data);
        setAuthorized(true);
      },
      (err) => {
        setAuthorized(false);
      }
    );
    navigate('/');
  };

  // Функция, вызываемая при нажатии кнопки "Удалить аккаунт"
  const del = () => {
    setShowDeleteModal(true);
  };

  // Функция, которая запускается при нажатии кнопки подтверждения в модальном окне
  const handleDeleteAccount = () => {
    // Запускаем обратный отсчёт
    setCountdownActive(true);
    setCountdown(30);
  };

  useEffect(() => {
    let interval;
    if (countdownActive && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdownActive && countdown === 0) {
      deleteAccount(
        (data) => {
          setDeleteSuccess(true);
          setCountdownActive(false);
        },
        (err) => {
          setDeleteError(`Ошибка удаления аккаунта: ${err?.message || 'Неизвестная ошибка'}`);
          setCountdownActive(false);
        }
      );
    }
    return () => clearInterval(interval);
  }, [countdownActive, countdown]);

  // После успешного удаления показываем сообщение и через 3 секунды перенаправляем пользователя
  useEffect(() => {
    let timeout;
    if (deleteSuccess) {
      timeout = setTimeout(() => {
        window.location.href = window.location.origin;
      }, 10000);
    }
    return () => clearTimeout(timeout);
  }, [deleteSuccess, navigate]);

  // Функция для закрытия модального окна, сброса отсчёта и отмены удаления
  const closeModal = () => {
    setShowDeleteModal(false);
    setDeleteError('');
    setDeleteSuccess(false);
    setCountdownActive(false);
    setCountdown(30);
  };

  return (
    <div className="flex w-full min-h-screen p-4 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
      <div className="w-full max-w-lg">
        <form className="bg-white shadow-2xl rounded-3xl px-8 pt-6 pb-8 mb-4">
          <div>
            <AlertAccent
              title="Аккаунт не подтвержден!"
              text="Для подтверждения аккаунта перейдите, пожалуйста, по ссылке, отправленной на Вашу почту"
              showAlert={!user.verified_at}
            />

            <table className="table-auto w-full text-base my-4">
              <tbody>
                <tr>
                  <td className="text-center border-y">
                    <p className="line-clamp-1">Email или логин</p>
                  </td>
                  <td className="text-center border-y">{user.username}</td>
                </tr>
                <tr>
                  <td className="text-center border-y">
                    <p className="line-clamp-1">ФИО</p>
                  </td>
                  <td className="text-center border-y">{user.fio}</td>
                </tr>
                <tr>
                  <td className="text-center border-y">
                    <p className="line-clamp-1">Телефон</p>
                  </td>
                  <td className="text-center border-y">{user.phone}</td>
                </tr>
                <tr>
                  <td className="text-center border-y">
                    <p className="line-clamp-1">Роль</p>
                  </td>
                  <td className="text-center border-y">{user.role}</td>
                </tr>
                <tr>
                  <td className="text-center border-y">
                    <p className="line-clamp-1">Статус</p>
                  </td>
                  <td className="text-center border-y">{user.verified_at ? 'Подтвержден' : 'Не подтвержден'}</td>
                </tr>
                <tr>
                  <td className="text-center border-y">
                    <p className="line-clamp-1">Версия web</p>
                  </td>
                  <td className="text-center border-y">
                    {process.env.REACT_APP_VER} ({process.env.REACT_APP_MODE})
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mb-4">
              <BtnFilled title="Удалить аккаунт" btnCb={del} />
            </div>

            <div className="mb-0">
              <BtnFilled title="Выход" btnCb={out} />
            </div>
          </div>
        </form>
      </div>

      {/* Модальное окно для подтверждения удаления аккаунта */}
      {showDeleteModal && (
        <Modal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          // Если возникла ошибка, вместо кнопки "Удалить" показываем "Закрыть"
          titleOn={deleteError ? 'Закрыть' : countdownActive ? 'Отмена' : 'Удалить'}
          saveCb={deleteError ? closeModal : countdownActive ? closeModal : handleDeleteAccount}
          // Не показываем кнопку "Удалить", если ошибка произошла или удаление прошло успешно
          showSave={!deleteSuccess && !deleteError}
          closeCb={closeModal}
          // Если ошибка – меняем подпись на кнопке отмены на "Закрыть"
          cancelTitle={deleteError ? 'Закрыть' : 'Отмена'}
          title="Вы действительно хотите удалить аккаунт?">
          {deleteSuccess ? (
            <AlertAccent
              title="Аккаунт успешно удалён"
              text="Удалены все данные, включая персональную информацию, историю действий и настройки профиля."
              showAlert={true}
            />
          ) : deleteError ? (
            <AlertAccent title="Ошибка удаления аккаунта" text={deleteError} showAlert={true} />
          ) : countdownActive ? (
            <p>Ваш аккаунт будет удалён через {countdown} секунд.</p>
          ) : (
            <p>После подтверждения удаления аккаунта запустится обратный отсчёт в 30 секунд, по окончании которого аккаунт будет удалён.</p>
          )}
        </Modal>
      )}
    </div>
  );
};

export default User;
